<template>
  <dashboard-card
      :title="title"
      :popover="popover"
  >
    <div class="d-flex h-100 align-items-center justify-content-center">
      <apexchart
          :type="baseChartOptions.chart.type"
          :height="baseChartOptions.chart.height"
          :options="chartOptions"
          :series="[seriesValue]"
      />
    </div>
  </dashboard-card>
</template>

<script>
import DashboardCard from '@/components/dashboards/dashboard-card'

export default {
  name: 'dashboard-radial-chart',
  components: { DashboardCard },
  props: {
    title: {
      type: String,
      required: true
    },
    popover: {
      type: String,
      required: true
    },
    seriesName: {
      type: String,
      required: true
    },
    seriesValue: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    baseChartOptions: {
      chart: {
        height: 250,
        type: 'radialBar',
        zoom: {
          enabled: false
        }
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '55%'
          },
          dataLabels: {
            show: true,
            name: {
              // offsetY: -10,
              show: true,
              // color: '#888',
              fontSize: '17px'
            },
            value: {
              // formatter: function(val) {
              //   return parseInt(val);
              // },
              // color: '#111',
              fontSize: '18px',
              show: true
            }
          }
        }
      }
    }
  }),
  computed: {
    chartOptions: function () {
      return {
        ...this.baseChartOptions, labels: [this.seriesName]
      }
    }
  }
}
</script>

<style scoped>

</style>
