import http from '@/http'
import { parseDataParam } from '@/modules/dashboards/toolbox/parseDataParam'

export async function usersOnline ({ startDate, endDate } = {}) {
  const data = await http.get('/admin/metrics/users/online', {
    params: {
      created_start: parseDataParam(startDate),
      created_end: parseDataParam(endDate)
    }
  }).then(res => res.data)
  return {
    current: data.total,
    peak: data.total_peak
  }
}
