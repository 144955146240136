import http from '@/http'
import { parseDataParam } from '@/modules/dashboards/toolbox/parseDataParam'

export async function ranking ({ startDate, endDate } = {}) {
  const data = await http.get('/admin/metrics/ranking', {
    params: {
      created_start: parseDataParam(startDate),
      created_end: parseDataParam(endDate)
    }
  }).then(response => response.data)

  return data.data.map(i => ({
    totalPoints: i.total,
    name: i.user.data.name,
    email: i.user.data.email,
    avatar: i.user.data.avatar ? i.user.data.avatar : null
  }))
}
