<template>
  <dashboard-card no-title>
    <div class="dl-container">

      <div
          v-for="(item, index) in list"
          :key="'dl-content-'+index"
          class="dl-content"
      >
        <div class="dl-item">
          <div class="dl-item-label">
            <span>{{ item.label }}</span>
            <i
                v-if="item.info"
                v-b-popover.hover.top="item.info"
                class='bx bx-info-circle'
            />
          </div>
          <div class="dl-item-value">
            <span>{{ item.value }}</span>
            <span
                v-if="item.valueEmphasis"
                class="dl-item-value-emphasis"
            >{{ item.valueEmphasis }}</span>
          </div>
        </div>
      </div>
    </div>
  </dashboard-card>
</template>

<script>
import DashboardCard from '@/components/dashboards/dashboard-card'

export default {
  name: 'dashboard-detail-list',
  components: { DashboardCard },
  props: {
    list: {
      type: Array
    }
  }
}
</script>

<style scoped lang="scss">

.dl-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-content: center;
}

.dl-content {
  height: 100%;
  display: grid;
  place-items: center start;
  padding: 16px 0;
}

.dl-item-label {
  font-weight: 500;
  font-size: 14px;
  color: #5F6368;
}

.dl-item-value {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-top: 10px;
  font-weight: 600;
  font-size: 24px;
  color: #2F3844;

  .dl-item-value-emphasis {
    display: inline-block;
    padding: 5px 10px;
    font-weight: 400;
    font-size: 14px;
    background: rgba(1, 179, 102, 0.31);
    border-radius: 5px;
  }
}

.dl-content:not(:first-child) {
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #2C343B3B;
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
