<template>
  <dashboard-card
      :title="title"
      :popover="popover"
  >
    <div>
      <apexchart
          :type="chartOptions.chart.type"
          :height="chartOptions.chart.height"
          :options="chart.options"
          :series="chart.data"
      />
    </div>
  </dashboard-card>
</template>

<script>

import DashboardCard from '@/components/dashboards/dashboard-card'

export default {
  name: 'dashboard-area-chart',
  components: { DashboardCard },
  props: {
    title: {
      type: String,
      required: true
    },
    popover: {
      type: String,
      required: true
    },
    seriesTitle: {
      type: String,
      required: true
    },
    seriesData: {
      type: Array,
      required: true
    },
    seriesLabel: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    chartOptions: {
      chart: {
        type: 'area',
        height: 250,
        zoom: {
          enabled: false
        }
      },
      // grid: {
      // padding: {
      //   top: 0,
      //   left: 0,
      //   bottom: 0,
      //   right: 0
      // }
      // },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      xaxis: {
        categories: [],
        labels: {
          show: true
        },
        axisBorder: {
          show: true
        }
      },
      yaxis: {
        title: {
          // text: 'Temperature'
        }
      },
      legend: {
        horizontalAlign: 'left'
      }
    }
  }),
  computed: {
    chart () {
      const data = {
        options: {
          ...this.chartOptions
        },
        data: [{
          name: this.seriesTitle,
          data: this.seriesData
        }]
      }
      data.options.xaxis.categories = this.seriesLabel
      data.options.yaxis.title.text = this.seriesTitle
      return data
    }
  }
}
</script>

<style scoped>

</style>
