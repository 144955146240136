<template>
  <dashboard-card no-title>
    <div class="dbnb-container h-100">
      <div class="dbnb-content" v-for="(item, index) in valueList" :key="'dbnb-content-'+index">
        <div class="dbnd-10">
          <div class="dbnb-content-title">
            {{ item.title }}
            <i
                v-if="item.info"
                class='bx bx-info-circle'
                v-b-popover.hover.top="item.info"
            />
          </div>
          <div class="dbnb-body text-truncate">
            {{ item.value }}
          </div>
        </div>
      </div>
    </div>
  </dashboard-card>
</template>

<script>
import DashboardCard from '@/components/dashboards/dashboard-card'

export default {
  name: 'dashboard-big-number-b',
  components: { DashboardCard },
  props: {
    valueList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.dbnb-container {
  display: flex;
  justify-content: space-between;
}

.dbnb-content {
  height: 100%;
  flex: 1 auto;
  //display: grid;
  //place-items: center;

  position: relative;

  &:not(:last-child)::before {
    content: '';
    width: 1px;
    height: 100%;
    background-color: #2C343B3B;
    position: absolute;
    right: 14px;
    top: 0;
  }

  .dbnd-10 {
    width: calc(100% - 20px);
    overflow: hidden;
  }
}

.dbnb-content-title {
  color: #5F6368;
  font-size: 14px;
  font-weight: 500;
}

.dbnb-body {
  color: #2F3844;
  font-size: 24px;
  font-weight: 600;
}
</style>
