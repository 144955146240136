import http from '@/http'
import { parseDataParam } from '@/modules/dashboards/toolbox/parseDataParam'

function getLast (list, limit) {
  if (!list || !list.length) {
    return []
  }
  if (limit === undefined) {
    return list
  }
  return list.slice(limit * -1)
}

export async function usersOnlineGraph ({ startDate, endDate } = {}) {
  const data = await http.get('/admin/metrics/users/online/graph', {
    params: {
      created_start: parseDataParam(startDate),
      created_end: parseDataParam(endDate)
    }
  }).then(response => response.data)
  return {
    list: getLast(data.series[0].data, 20),
    label: getLast(data.categories, 20)
  }
}
