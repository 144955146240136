import http from '@/http'
import { parseDataParam } from '@/modules/dashboards/toolbox/parseDataParam'

export async function usersOnlineRate ({ startDate, endDate } = {}) {
  const data = await http.get('/admin/metrics/subscribers/online', {
    params: {
      created_start: parseDataParam(startDate),
      created_end: parseDataParam(endDate)
    }
  }).then(response => response.data)
  return {
    rate: data.rate[0]
  }
}
