<template>
  <div>
    <dashboard-card
        :title="title"
        :popover="popover"
    >
      <b-table
          striped
          hover
          borderless
          outlined
          no-border-collapse
          head-variant="light"
          :items="items"
          :fields="fields"
      >
        <template #cell(index)="data">
          <b>{{ data.index + 1 }}.</b>
        </template>
        <template #cell(pic_url)="data">
          <img :src="data.value" width="50" height="50" alt="avatar" class="rounded avatar-xs">
        </template>
      </b-table>
    </dashboard-card>
  </div>
</template>

<script>
import DashboardCard from '@/components/dashboards/dashboard-card'
export default {
  name: 'dashboard-table-data',
  components: { DashboardCard },
  props: {
    title: {
      type: String,
      required: true
    },
    popover: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    fields: [
      { key: 'index', label: '' },
      { key: 'pic_url', label: 'Avatar' },
      { key: 'name', label: 'Nome' },
      { key: 'email', label: 'E-mail' },
      { key: 'points', label: 'Pontuação' }
    ]
  })
}
</script>

<style scoped>

</style>
