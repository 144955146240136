import http from '@/http'
import { parseDataParam } from '@/modules/dashboards/toolbox/parseDataParam'

export async function totalMetricsEvent ({ startDate, endDate } = {}) {
  const data = await http.get('/admin/metrics/total-metrics-event', {
    params: {
      created_start: parseDataParam(startDate),
      created_end: parseDataParam(endDate)
    }
  }).then(response => response.data)
  return {
    survey: data.survey ?? 0,
    quiz: data.quiz ?? 0,
    poll: data.poll ?? 0,
    asking: data.asking ?? 0,
    certs: data.certs ?? 0,
    postsFeed: data.postsFeed ?? 0,
    postsLikes: data.postsLikes ?? 0,
    postsComments: data.postsComments ?? 0,
    wordCloud: data.wordCloud ?? 0
  }
}
