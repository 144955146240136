import http from '@/http'
import { parseDataParam } from '@/modules/dashboards/toolbox/parseDataParam'

export async function reactEmojis ({ startDate, endDate, roomId } = {}) {
  const data = await http.get('/admin/metrics/react-emojis', {
    params: {
      created_start: parseDataParam(startDate),
      created_end: parseDataParam(endDate),
      room_id: roomId
    }
  }).then(response => response.data)
  return {
    rocket: Number(data.rocket),
    likes: Number(data.likes),
    love: Number(data.love),
    wow: Number(data.wow),
    hands: Number(data.hands),
    incredible: Number(data.incredible)
  }
}
