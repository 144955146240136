import http from '@/http'
import { parseDataParam } from '@/modules/dashboards/toolbox/parseDataParam'

export async function subscribers ({ startDate, endDate } = {}) {
  const data = await http.get('/admin/metrics/subscribers', {
    params: {
      created_start: parseDataParam(startDate),
      created_end: parseDataParam(endDate)
    }
  }).then(response => response.data)
  return {
    total: data.total,
    last1h: data.total_1hr,
    last1hGrowth: data.total_1hr_perc,
    last24h: data.total_24hr,
    last24hGrowth: data.total_24hr_perc,
    last7d: data.total_7d,
    last7dGrowth: data.total_7d_perc
  }
}
